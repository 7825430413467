.custome-input-group {
    border-bottom: 1px solid #F9F9F9 ;
    color: #8E8E8E;
    padding: 5px;
}



.custome-input-group input, .custome-input-group textarea {
    background-color: transparent;
    box-shadow: none;
    border: none;
    outline: none;
    padding: 0px 10px;
}

.custome-input-group textarea {
    background-color: #F9F9F9;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
}