/* @import './main.css'; */

@font-face {
    font-family: "DINPro";
    src: local('DINPro'), url(../assets/fonts/DINPro-Light.ttf) format('truetype');
  }
  @font-face {
    font-family: "DINPro";
    src: local('DINPro'), url(../assets/fonts/DINPro-Bold.ttf) format('truetype');
    font-weight: bold;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: "DINPro";
    color: #302124;
  }

  .fadeInBottomScroll {
    animation-name: fadeInBottomScrollAnimation;
    animation-duration: 1s;
    animation-timing-function: linear;
  }

  .fadeOutBottomScroll {
    animation-name: fadeOutBottomScrollAnimation;
    animation-duration: 1s;
    animation-timing-function: linear;
  }

  .fadeOutTopScroll {
    animation-name: fadeOutTopScrollAnimation;
    animation-duration: 1s;
    animation-timing-function: linear;
  }

  .fadeInTopScroll {
    animation-name: fadeInTopScrollAnimation;
    animation-duration: 1s;
    animation-timing-function: linear;
  }

  .fadeOut{
    animation-name: fadeOutAnimation;
    animation-duration: 1s;
  }

  .fadeIn{
    animation-name: fadeInAnimation;
    animation-duration: 1s;
  }

  @keyframes fadeOutAnimation {
    0% {
        opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutTopScrollAnimation {
  from {
      top: 0vh;
      /* opacity: 0; */
  }
  to {
      top: 100vh;
      /* opacity: 1; */
  }
}

@keyframes fadeInTopScrollAnimation {
  from {
      top: -100vh;
      /* opacity: 0; */
  }
  to {
      top: 0vh;
      /* opacity: 1; */
  }
}

@keyframes fadeInBottomScrollAnimation {
    from {
        top: 100vh;
        /* opacity: 0; */
    }
    to {
        top: 0vh;
        /* opacity: 1; */
    }
}

@keyframes fadeOutBottomScrollAnimation {
    from {
        top: 0vh;
        /* opacity: 1; */
    }
    to {
        top: -100vh;
        /* opacity: 0; */
    }
}