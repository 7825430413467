.welcome-text {
    width: 100%;
    font-weight: 700;
    text-align: center;
}

.letter-popup{
    max-width: 500px;
    margin-top: 30px;
    height: 80vh;
    overflow: auto;
}

.welcome-description {
    font-weight: 400;
    display: block;
}

.message-container {
    clip-path: polygon(0 0, 100% 0, 100% 78%, 74% 78%, 70% 100%, 63% 78%, 0 78%);
}

.message-wrapper {
    top: 80px; 
    left:-70px; 
    cursor:pointer;
}

.rect {
    clip-path: polygon(42% 46%, 58% 46%, 100% 100%, 0% 100%);
    margin-top: -240px;
    width: 100%;
}

#playAroundButton {
    top:25%; 
    right:43%;
}

@media (min-width: 641px) and (max-width: 1024px) {
    #playAroundButton {
        top:20%; 
        right:40%;
    }
    .message-wrapper {
        left:-95px;
    }
}

@media (max-width: 640px) {
    .rect {
        -webkit-clip-path: polygon(33% 40%, 67% 40%, 100% 55%, 100% 100%, 0 100%, 0 55%);
        clip-path: polygon(33% 40%, 67% 40%, 100% 55%, 100% 100%, 0 100%, 0 55%);
    }
    #playAroundButton {
        top:20%; 
        right:27%;
    }
    .rect {
        margin-top: -200px;
    }
    .message-wrapper {
        top: 20px;
    }
}
